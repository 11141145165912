import React from "react"
import Layout from "../components/layout"
import WeCanHelp from "../components/homepage/we_can_help"

const HowItWorksPage = () => {
  return (
    <Layout>
      <div className="whatWeTreatPage">
                  <WeCanHelp />
      </div>  
    </Layout>
  )
}

export default HowItWorksPage
